
export const ecosystemFields = [
  {
    name: 'lowCarbonFactor',
    label: 'Low Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'e'
  },
  {
    name: 'medianCarbonFactor',
    label: 'Median Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'e'
  },
  {
    name: 'highCarbonFactor',
    label: 'High Carbon Factor',
    suffix: 'kgCO₂e/kg',
    prefix: 'e'
  },
  {
    name: 'density',
    label: 'Density',
    suffix: 'kg/M^3',
    prefix: 'e'
  }, {
    name: 'replacementCycles',
    label: 'Replacement Cycles',
    prefix: 'e'
  }, {
    name: 'existingCarbonStored',
    label: 'Existing Carbon Stored',
    suffix: 'kgCO₂e/M^3',
    prefix: 'e'
  },
  {
    name: 'carbonSequestered60Years',
    label: 'Carbon Sequestered @ 60 Years',
    suffix: 'kgCO₂e/M^3',
    prefix: 'e'
  },
  {
    name: 'embodiedCarbonCost',
    label: 'Embodied Carbon Cost',
    suffix: 'Avg',
    prefix: 'e'
  },
  {
    name: 'netCarbonSequestered',
    label: 'Net Carbon Sequestered',
    suffix: '(60 Years)',
    prefix: 'e'
  },
  {
    name: 'carbonStoredInMaterialsOrPreservedEcosystems',
    label: 'Carbon Stored In Materials Or Preserved Ecosystems',
    suffix: 'kgCO₂e/m^2',
    prefix: 'e'
  },
  {
    name: 'totalBiomass',
    label: 'Total Biomass (AGB + BGB)',
    suffix: 'Ckg/m^2',
    prefix: 'e'
  },
  {
    name: 'potentialSoilOrganicCarbonAtMaturity',
    label: 'Potential Soil Organic Carbon (SOC) At Maturity',
    suffix: 'Ckg/m^2',
    prefix: 'e'
  },
  {
    name: 'totalStoredCarbonPotential',
    label: 'TCP/CCC: Total Stored Carbon Potential',
    prefix: 'e'
  }, {
    name: 'distributedSequestrationRate',
    label: 'DSR: Distributed Sequestration Rate',
    prefix: 'e'
  },
  {
    name: 'totalSequestrationRate',
    label: 'Total Sequestration Rate',
    prefix: 'e'
  }, {
    name: 'yearAssumptionOfMaturity',
    label: 'Year Assumption Of Maturity',
    prefix: 'e'
  }, {
    name: 'studyYearForRestorationOrNewPlantingMaturity',
    label: 'Study Year For Restoration Or New Planting Maturity',
    prefix: 'e'
  }, {
    name: 'avgGWPStoredInMatureEcosystems',
    label: 'Avg. GWP Stored In Mature Ecosystems',
    suffix: 'kgCO₂e/M^2',
    prefix: 'e'
  },
  {
    name: 'avgGWPAnnualSequestrationRate',
    label: 'Avg. GWP Annual Sequestration Rate',
    suffix: 'kgCO₂e/M^2/Yr',
    prefix: 'e'
  },
  {
    name: 'restoredEcosystemAnticipatedCarbonSequestrationByStudyYear',
    label: 'Restored Ecosystem Anticipated Carbon Sequestration By Study Year',
    suffix: 'kgCO₂e/M^2',
    prefix: 'e'
  },
  {
    name: 'allochthonousCarbon',
    label: 'Allochthonous Carbon (5%)',
    suffix: 'kgCO₂e/M^2/Yr',
    prefix: 'e'
  },
  {
    name: 'totalAdditionalCarbonSequestrationForPreservedEcosystemsByStudyYear',
    label: 'Total Additional Carbon Sequestration For Preserved Ecosystems By Study Year',
    suffix: 'kgCO₂e/M^2',
    prefix: 'e'
  }
]
export const ecosystemForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'ccEcosystemId',
    label: 'CC Ecosystem Id'
  },
  {
    name: 'ecosystemCategory',
    elementType: 'select',
    label: 'Ecosystem Category'
  },
  ...ecosystemFields,
  {
    name: 'notes',
    label: 'Notes'
  },
  {
    name: 'citations',
    label: 'Citations'
  }
]
export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type !== 'hydrozone' && (element.type === 'plant' || element.type === 'maintenance')
}
