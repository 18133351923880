<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="ecosystems"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Ecosystems
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            width="800"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Ecosystem
              </v-btn>
            </template>
            <NewEcosystemForm
              @created="handleCreatedEcosystem"
              @canceled="handleCanceledEcosystem"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/ecosystems/${item._id}`"
          @delete="deleteEcosystem(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'
import { ecosystemFields } from './constants'
import NewEcosystemForm from './EcosystemForm'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminEcosystem',
  components: {
    NewEcosystemForm,
    EditAndDeleteButtons
  },
  data () {
    const ecosystemColumns = ecosystemFields.map(({ name, label, suffix }) => {
      const text = label + (suffix ? ` (${suffix})` : '')
      return {
        text,
        value: name
      }
    })
    const headers = [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'CC - Ecosystem Id',
        value: 'ccEcosystemId'
      },
      {
        text: 'Ecosystem Category',
        value: 'ecosystemCategory'
      },
      ...ecosystemColumns,
      {
        text: 'Notes',
        value: 'notes'
      },
      {
        text: 'Citations',
        value: 'citations'
      },
      {
        value: 'actions',
        sortable: false
      }
    ]
    return {
      headers,
      ecosystems: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchEcosystemCategories()
    await this.fetchEcosystems()
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchEcosystemCategories () {
      const { data: ecosystemCategories } = await this.$axios.get('/ecosystem_categories')
      this.ecosystemCategories = ecosystemCategories.map(ecosystemCategory => ({
        ...ecosystemCategory
      }))
    },
    async fetchEcosystems () {
      const { data: ecosystems } = await this.$axios.get('/ecosystems')
      const mergedEcosystems = ecosystems.map((ecosystem) => ({ ...ecosystem, ecosystemCategory: this.ecosystemCategories.find((category) => category._id === ecosystem.ecosystemCategory)?.name }))

      this.ecosystems = mergedEcosystems.map(ecosystem => ({
        ...ecosystem
      }))
    },
    async deleteEcosystem (id) {
      try {
        await this.$axios.delete(`/ecosystems/${id}`)
        await this.fetchEcosystems()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete ecosystem. Please try again.' })
        throw err
      }
    },
    handleCanceledEcosystem () {
      this.showForm = false
    },
    handleCreatedEcosystem () {
      this.showForm = false
      this.fetchEcosystems()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
