<template>
  <div>
    <SimpleTableForm
      :id="id"
      title="Create Ecosystem"
      :table-config="tableConfig"
      :table-config-categories="tableConfigCategories"
      :return-route="adminRoute"
      :server-type="serverType"
      @created="$emit('created')"
    />
  </div>
</template>

<script>

import SimpleTableForm from '@/components/form/SimpleTableForm.vue'
import { ecosystemForm } from '@/views/admin/ecosystems/constants'

export default {
  name: 'EcosystemForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      tableConfig: ecosystemForm,
      tableConfigCategories: {},
      serverType: 'ecosystems',
      adminRoute: '/admin/ecosystems'
    }
  },
  async created () {
    this.tableConfigCategories['ecosystemCategory'] = (await this.$axios.get('/ecosystem_categories')).data
    this.loading = false
  }
}
</script>
